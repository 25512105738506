type TranslationKey =
  | 'agreement_checkbox_title'
  | 'agreement_checkbox_title_webid_confirmed'
  | 'privacy_notice'
  | 'dataProtectionTermsAccept_end'
  | 'dataProtectionTermsAccept_start'
  | 'AldLeasingDataProtectionAccept'
  | 'webIdConfirmation'
  | 'dataInformationConfirmation'
  | 'agb'
  | 'agb_link'
  | 'close_button'
  | 'consent'
  | 'consent_link'
  | 'consent_end'
  | 'confirm_button'
  | 'confirm_button_webid_confirmed'
  | 'steps_to_digitally_sign_title'
  | 'contract_documents'
  | 'leasing'
  | 'customer'
  | 'view_leasing_contract'
  | 'step_one'
  | 'step_two'
  | 'step_three';

/* eslint-disable camelcase */
const EN: Record<TranslationKey, string> = {
  agreement_checkbox_title: 'We still need the following approvals:',
  agreement_checkbox_title_webid_confirmed: 'We still need the following approvals:',
  dataProtectionTermsAccept_start: 'Ich habe die ',
  privacy_notice: 'Notes on data protection',
  dataProtectionTermsAccept_end:
    'taken note of taken. I agree that the following entries and uploaded documents may be collected electronically by ALD AutoLeasing D GmbH for the purpose of a quick and smooth conclusion of the contract. and stored for up to 90 days. I also affirm that I am authorized to enter the data entered as  well as to upload the respective documents.',
  AldLeasingDataProtectionAccept: '',
  webIdConfirmation: 'I confirm that I have informed the lessee about the WebID process.',
  dataInformationConfirmation: 'I confirm that I have informed the lessee about the data information process.',
  agb: 'Here you can find them',
  agb_link: 'General Terms and Conditions (GTC).',
  close_button: 'Close',
  consent: 'You can email this consent at any time for the future',
  consent_link: 'datenschutz-widerspruch@aldautomotive.com',
  consent_end: 'withdraw',
  confirm_button: 'Confirm',
  confirm_button_webid_confirmed: 'Everyone has signed',
  steps_to_digitally_sign_title: 'Steps to digitally sign the leasing contract',
  contract_documents: 'Contract documents',
  leasing: 'Leasing',
  customer: 'Customer',
  view_leasing_contract: 'You can view the leasing contract documents to be signed here.',
  step_one: 'Offer the lessee the lease agreement for prior viewing.',
  step_two: 'Obtain necessary consents using the checkboxes.',
  step_three:
    'Click "Confirm and Sign Digitally", authorized signers will receive an email with a link from WebID to start the lease signing process.',
};
const DE: Record<TranslationKey, string> = {
  agreement_checkbox_title: 'Wir benötigen noch folgende Zustimmungen:',
  agreement_checkbox_title_webid_confirmed: 'Zustimmungen wurden bereits erteilt',
  dataProtectionTermsAccept_start: 'Ich habe die ',
  privacy_notice: 'Hinweise zum Datenschutz',
  dataProtectionTermsAccept_end:
    'zur Kenntnis genommen. Ich stimme zu, dass die nachfolgend getätigten Eingaben sowie hochgeladenen Dokumente durch die ALD AutoLeasing D GmbH zum Zwecke eines schnellen und reibungslosen Vertragsschlusses elektronisch erhoben und bis zu 90 Tage gespeichert werden. Ich versichere zudem, dass ich zur Angabe der eingegebenen Daten sowie zum Hochladen der jeweiligen Dokumente berechtigt bin.',
  AldLeasingDataProtectionAccept:
    'Ich willige ein, den Leasingnehmer darüber informiert zu haben, dass die ALD AutoLeasing D GmbH der WebID Solutions ("WebID") die hier gemachten Angaben sowie den Umstand der Beantragung dieses Leasingvertrages zwecks Identifikation und/oder digitaler Unterzeichnung des Vertrages in erforderlichen Umfang übermittelt sowie entsprechende Daten von der WebID Solutions GmbH erhalt. Ich versichere, dass ich zu dieser Einwilligung berechtigt bin. Die Verarbeitung der Daten erfolgt unter Beachtung der einschlägigen datenschutzrechtlichen Vorschriften, insbesondere der Vorschriften der DS-GVO und des Bundesdatenschutzgesetzes (BDSG).',
  webIdConfirmation:
    'Ich bestätige, dass der Leasingnehmer die <a href="https://webid-solutions.de/agb/" className="text__link" target="_blank">Allgemeinen Geschäftsbedingungen (AGB)</a> der WebID Solutions GmbH zur Kenntnis genommen hat.',
  dataInformationConfirmation:
    'Ich bestätige, dass der Leasingnehmer die ausführlichen Informationen zum Umgang mit den personenbezogenen Daten durch die WebID Solutions GmbH <a href="https://webid-solutions.de/datenschutzbestimmungen" className="text__link" target="_blank">(Datenschutzerklärung)</a> zur Kenntnis genommen hat.',
  agb: 'Hier finden Sie unsere',
  agb_link: 'Allgemeinen Geschäftsbedingungen (AGB).',
  close_button: 'Schließen',
  consent: 'Sie können diese Einwilligungen jederzeit für die Zukunft per E-Mail an',
  consent_link: 'datenschutz-widerspruch@aldautomotive.com',
  consent_end: 'widerrufen.',
  confirm_button: 'Bestätigen und digital unterzeichnen',
  confirm_button_webid_confirmed: 'Jeder Unterzeichner hat eine E-Mail bekommen',
  steps_to_digitally_sign_title: 'Schritte zum digitalen Unterzeichnen des Leasingvertrages',
  contract_documents: 'Vertragsunterlagen',
  leasing: 'Leasing',
  customer: 'Kunde',
  view_leasing_contract:
    'Die zu unterzeichnenden Leasingvertragsunterlagen können Sie hier herunterladen und einsehen.',
  step_one: 'Bieten Sie dem Leasingnehmer den Leasingvertrag inkl. seiner Bestandteile zur vorherigen Sichtung an.',
  step_two: 'Holen Sie notwendige Zustimmungen über die Checkboxen ein.',
  step_three:
    'Nachdem Sie auf "Bestätigen und digital unterzeichnen" geklickt haben, erhalten die Zeichnungsberechtigen eine E-mail, um den digitalen Zeichnungsprozess mit WebID für den Leasingvertrag zu starten.',
};
const translationsForLocale = (locale: 'en' | 'de') => {
  if (locale === 'de') {
    return DE;
  }
  return EN;
};
// Use this method only for testing purposes! never use it in production code.
export const fullTranslate = (key: TranslationKey): string => translationsForLocale('de')[key] || key;
const isTest: boolean = process.env.NODE_ENV === 'test';
const t = (key: TranslationKey): string => {
  if (isTest) {
    return key;
  }
  return fullTranslate(key);
};
const tWithParams = (key: TranslationKey, params: Record<string, string> = {}): string => {
  const template = t(key);
  return template
    ? Object.keys(params).reduce((result, attr) => result.replace(`{${attr}}`, params[attr]), template)
    : '';
};

const I18nS = {
  t,
  tWithParams,
};
export default I18nS;
