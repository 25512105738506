import React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function DataSearchIcon() {
  return (
    <svg width="98" height="129" viewBox="0 0 115 136" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_2136_12164)">
        <path
          d="M5.21973 5.79395C5.21973 3.58481 7.01059 1.79395 9.21973 1.79395H77.5437C78.6102 1.79395 79.6326 2.21987 80.3836 2.9771L85.7704 8.40803L91.4313 14.1273C92.1726 14.8762 92.5884 15.8874 92.5884 16.9412V109.131C92.5884 111.34 90.7976 113.131 88.5884 113.131H9.21973C7.01059 113.131 5.21973 111.34 5.21973 109.131V5.79395Z"
          fill="white"
        />
      </g>
      <path
        d="M5.21973 5.79395C5.21973 3.58481 7.01059 1.79395 9.21973 1.79395H77.5437C78.6102 1.79395 79.6326 2.21987 80.3836 2.97709L85.7704 8.40803L91.4313 14.1273C92.1726 14.8762 92.5884 15.8874 92.5884 16.9412V109.131C92.5884 111.34 90.7976 113.131 88.5884 113.131H9.21973C7.01059 113.131 5.21973 111.34 5.21973 109.131V5.79395Z"
        fill="white"
      />
      <line
        x1="46.0811"
        y1="60.6484"
        x2="76.5384"
        y2="60.6484"
        stroke="#031A6B"
        strokeOpacity="0.3"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="22.4644"
        y1="92.7939"
        x2="52.9217"
        y2="92.7939"
        stroke="#031A6B"
        strokeOpacity="0.3"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="46.0811"
        y1="54.0884"
        x2="76.5384"
        y2="54.0884"
        stroke="#031A6B"
        strokeOpacity="0.3"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="22.4644"
        y1="86.2339"
        x2="52.9217"
        y2="86.2339"
        stroke="#031A6B"
        strokeOpacity="0.3"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="46.7373"
        y1="66.5527"
        x2="62.7621"
        y2="66.5527"
        stroke="#031A6B"
        strokeOpacity="0.3"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="23.1201"
        y1="98.6982"
        x2="39.1449"
        y2="98.6982"
        stroke="#031A6B"
        strokeOpacity="0.3"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="23.4644"
        y1="18.3188"
        x2="43.666"
        y2="18.3188"
        stroke="#031A6B"
        strokeOpacity="0.5"
        strokeWidth="5"
        strokeLinecap="round"
      />
      <line
        x1="22.4644"
        y1="27.8237"
        x2="76.5387"
        y2="27.8237"
        stroke="#031A6B"
        strokeOpacity="0.3"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="22.4644"
        y1="33.7515"
        x2="52.0783"
        y2="33.7515"
        stroke="#031A6B"
        strokeOpacity="0.3"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <g filter="url(#filter1_d_2136_12164)">
        <path d="M79.3507 14.9143L92.4712 15.5703L78.6946 1.79375L79.3507 14.9143Z" fill="white" />
      </g>
      <circle cx="52.5859" cy="66.5" r="29" fill="#B5D8D4" fillOpacity="0.75" stroke="#4EA699" strokeWidth="5" />
      <rect
        x="68.4761"
        y="98.9209"
        width="13.5765"
        height="40.1124"
        rx="6.78825"
        transform="rotate(-45 68.4761 98.9209)"
        fill="#4EA699"
      />
      <path
        d="M85 115.5L88.7065 119.206C93.8297 124.33 102.515 119.899 101.375 112.744V112.744L101.545 112.91C104.238 115.537 104.265 119.859 101.604 122.52V122.52C98.9712 125.153 94.7034 125.158 92.064 122.531L85 115.5Z"
        fill="#4B978C"
      />
      <line x1="81.847" y1="98" x2="91.108" y2="107.261" stroke="white" strokeWidth="2.61207" strokeLinecap="round" />
      <line
        x1="94.1893"
        y1="110.343"
        x2="96.6621"
        y2="112.816"
        stroke="white"
        strokeWidth="2.61207"
        strokeLinecap="round"
      />
      <path
        d="M64.4972 47.0028C67.7209 48.864 70.4359 51.4916 72.4016 54.6527C74.3673 57.8138 75.5231 61.411 75.7665 65.1254C76.01 68.8399 75.3336 72.5571 73.7974 75.9478C72.2611 79.3385 69.9123 82.2979 66.9591 84.564C64.0058 86.8301 60.5393 88.3329 56.8666 88.9392C53.1938 89.5456 49.4282 89.2369 45.9033 88.0403C42.3784 86.8438 39.203 84.7964 36.6583 82.0795C34.1137 79.3626 32.2784 76.0601 31.3149 72.4644C32.0777 75.3112 35.7282 78.0499 37.7429 80.2009C40.1933 81.6157 43.02 82.8225 45.8108 83.7699C48.6016 84.7172 53.1908 84.7681 56.0986 84.288C59.0065 83.8079 61.7511 82.6181 64.0892 80.824C66.4274 79.0298 68.6306 76.5811 69.8469 73.8966C71.0633 71.2121 71.8922 66.1484 71.6995 63.2075C71.5067 60.2666 71.4032 56.5875 69.8469 54.0847C68.2906 51.5819 67.0495 48.4764 64.4972 47.0028Z"
        fill="white"
        fillOpacity="0.7"
      />
      <ellipse
        cx="51.1253"
        cy="49.727"
        rx="9.11715"
        ry="6.28769"
        transform="rotate(-2.6891 51.1253 49.727)"
        fill="white"
        fillOpacity="0.7"
      />
      <circle
        cx="37.3343"
        cy="53.7189"
        r="2.41598"
        transform="rotate(-2.6891 37.3343 53.7189)"
        fill="white"
        fillOpacity="0.7"
      />
      <defs>
        <filter
          id="filter0_d_2136_12164"
          x="0.219727"
          y="1.79395"
          width="97.3687"
          height="126.337"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.614167 0 0 0 0 0.614167 0 0 0 0 0.733333 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2136_12164" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2136_12164" result="shape" />
        </filter>
        <filter
          id="filter1_d_2136_12164"
          x="75.6948"
          y="0.793945"
          width="19.7764"
          height="19.7764"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.832587 0 0 0 0 0.851891 0 0 0 0 0.920833 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2136_12164" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2136_12164" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default DataSearchIcon;
