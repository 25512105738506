import React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function CheckboxIcon() {
  return (
    <svg width="98" height="129" viewBox="0 0 98 129" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_2136_12166)">
        <path
          d="M5.21973 5.79395C5.21973 3.58481 7.01059 1.79395 9.21973 1.79395H77.5437C78.6102 1.79395 79.6326 2.21987 80.3836 2.9771L85.7704 8.40803L91.4313 14.1273C92.1726 14.8762 92.5884 15.8874 92.5884 16.9412V109.131C92.5884 111.34 90.7976 113.131 88.5884 113.131H9.21973C7.01059 113.131 5.21973 111.34 5.21973 109.131V5.79395Z"
          fill="white"
        />
      </g>
      <line
        x1="36.5"
        y1="60.5"
        x2="76.5"
        y2="60.5"
        stroke="#031A6B"
        strokeOpacity="0.3"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="36.5"
        y1="92.7939"
        x2="66.9574"
        y2="92.7939"
        stroke="#031A6B"
        strokeOpacity="0.3"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="36.5"
        y1="54.5"
        x2="76.5"
        y2="54.5"
        stroke="#031A6B"
        strokeOpacity="0.3"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="36.5097"
        y1="86.2437"
        x2="74.5098"
        y2="86.4903"
        stroke="#031A6B"
        strokeOpacity="0.3"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="36.5"
        y1="66.5"
        x2="62.5"
        y2="66.5"
        stroke="#031A6B"
        strokeOpacity="0.3"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="36.5"
        y1="99.5"
        x2="70.5"
        y2="99.5"
        stroke="#031A6B"
        strokeOpacity="0.3"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="23.4644"
        y1="18.3188"
        x2="43.666"
        y2="18.3188"
        stroke="#031A6B"
        strokeOpacity="0.5"
        strokeWidth="5"
        strokeLinecap="round"
      />
      <line
        x1="22.4644"
        y1="27.8237"
        x2="76.5387"
        y2="27.8237"
        stroke="#031A6B"
        strokeOpacity="0.3"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="22.4644"
        y1="33.7515"
        x2="52.0783"
        y2="33.7515"
        stroke="#031A6B"
        strokeOpacity="0.3"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <g filter="url(#filter1_d_2136_12166)">
        <path d="M79.3507 14.9143L92.4712 15.5703L78.6946 1.79375L79.3507 14.9143Z" fill="white" />
      </g>
      <rect x="22.25" y="50.25" width="7.5" height="7.5" rx="3" fill="#4EA699" />
      <mask
        id="mask0_2136_12166"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="21"
        y="49"
        width="10"
        height="10"
      >
        <path d="M21 49H31V59H21V49Z" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2136_12166)">
        <path
          d="M25.3857 55.6873L28.3647 52.6978L27.8752 52.208L25.3857 54.6978L24.1565 53.479L23.667 53.9685L25.3857 55.6873Z"
          fill="white"
        />
        <path
          d="M25.3857 55.6873L28.3647 52.6978L27.8752 52.208L25.3857 54.6978L24.1565 53.479L23.667 53.9685L25.3857 55.6873Z"
          fill="white"
        />
      </g>
      <rect x="22.25" y="81.25" width="7.5" height="7.5" rx="3" fill="#4EA699" />
      <mask
        id="mask1_2136_12166"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="21"
        y="80"
        width="10"
        height="10"
      >
        <path d="M21 80H31V90H21V80Z" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask1_2136_12166)">
        <path
          d="M25.3857 86.6873L28.3647 83.6978L27.8752 83.208L25.3857 85.6978L24.1565 84.479L23.667 84.9685L25.3857 86.6873Z"
          fill="white"
        />
        <path
          d="M25.3857 86.6873L28.3647 83.6978L27.8752 83.208L25.3857 85.6978L24.1565 84.479L23.667 84.9685L25.3857 86.6873Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2136_12166"
          x="0.219727"
          y="1.79395"
          width="97.3687"
          height="126.337"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.614167 0 0 0 0 0.614167 0 0 0 0 0.733333 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2136_12166" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2136_12166" result="shape" />
        </filter>
        <filter
          id="filter1_d_2136_12166"
          x="75.6948"
          y="0.793945"
          width="19.7764"
          height="19.7764"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.832587 0 0 0 0 0.851891 0 0 0 0 0.920833 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2136_12166" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2136_12166" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default CheckboxIcon;
