import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { SignedPdf } from '../assets/icons';
import './SuccessPage.scss';

const SuccessPage = () => (
  <div className="success-page">
    <Row className="panel">
      <Col xs="12">
        <SignedPdf />
      </Col>
    </Row>
    <Row>
      <Col xs="12">
        <span className="success-page__title">Super, fast geschafft!</span>
      </Col>
      <Col xs="12" className="success-page-description text-left">
        <span className="success-page__subtitle">
          <p>
            Für den digitalen Signatur-Prozess werden gerade alle notwendigen Kundendaten an unseren Dienstleister WebID
            Solutions GmbH übertragen.
            <br /> Im nächsten Schritt bekommt jede Person, die den Leasingvertrag digital signieren wird, eine E-Mail
            mit weiteren Instruktionen von der WebID Solutions GmbH zugesendet.
          </p>
          <p>
            Als Händler geht es für Sie in Ihrem Händlersystem weiter. <br /> Wie gewohnt, können Sie sich als Händler
            die Händlerunterlagen im Bereich „Dokumente“ für ihre Unterlagen herunterladen und speichern. Im Falle einer
            Rückkaufvereinbarung oder im Falle eines Gebrauchtwagenleasings (bei Gebrauchtwagen den Kaufvertrag für
            Gebrauchtwagenleasing) laden Sie die vom Zeichnungsberechtigten Ihres Autohauses unterzeichneten Unterlagen
            über den Händler Upload-Service hoch.
          </p>
        </span>
      </Col>
      <Col xs="12" className="close-button-container">
        <span className="close-panel">Sie können dieses Browserfenster jetzt schließen.</span>
      </Col>
    </Row>
  </div>
);

export default SuccessPage;
