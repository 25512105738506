import { Checkbox } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { NavigateFunction } from 'react-router-dom';
import { documentService } from '../Services/DocumentService';
import I18nS from '../Services/I18nService';
import { useFileContext } from './FileContext';
import './WebIdDocuments.scss';
import NotificationService from '../Services/NotificationService';
import EnvironmentService from '../Services/EnvironmentService';

const AgreementCheckbox = ({ navigate }: { navigate: NavigateFunction }) => {
  const { t } = I18nS;
  const {
    paramsData: { token, partner, angebotTyp, transactionId },
  } = useFileContext();

  const [isLoading, setIsLoading] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [isWebIdConfirmed, setIsWebIdConfirmed] = useState(false);

  const [state, setState] = useState({
    dataProtectionTermsAccept: false,
    AldLeasingDataProtectionAccept: false,
  });

  useEffect(() => {
    if (transactionId) {
      const isHasExpired = async () => {
        await documentService.hasExpired({ transactionId, setIsExpired });
      };
      isHasExpired();
      documentService.customerWebIdConfirmed({ transactionId, setIsWebIdConfirmed });
    }
  }, [transactionId, isExpired, setIsExpired, isWebIdConfirmed]);

  useEffect(() => {
    if (isExpired) {
      NotificationService.showToastNotificationToUser(
        'Die Gültigkeit des Leasingantrages ist abgelaufen. <br /> Bitte Neukalkulation erstellen.',
        { persistant: true, techMessage: '', isExpired },
      );
    }
  }, [isExpired]);

  const handleTermsConditions = async () => {
    await documentService.getTermsCondition({ partner, angebotTyp });
  };

  const handleVideoID = async () => {
    setIsLoading(true);
    await documentService.startVideoId({
      partner,
      transactionId,
    });
    navigate(`/success?token=${token}&partner=${partner}&transactionId=${transactionId}`);
    setIsLoading(false);
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const { dataProtectionTermsAccept, AldLeasingDataProtectionAccept } = state;

  const isAllChecked = dataProtectionTermsAccept && AldLeasingDataProtectionAccept;

  const navToDatenschutz = (): string =>
    `${EnvironmentService.getDatenschutzUrl()}/?angebotTyp=${angebotTyp}&partner=${partner}`;

  return (
    <div data-testid="agreement-checkbox" className="area">
      <Row className="panel">
        <Col xs="12" className="content-title">
          {isWebIdConfirmed ? t('agreement_checkbox_title_webid_confirmed') : t('agreement_checkbox_title')}
        </Col>
      </Row>
      <Row className="panel">
        <Col xs={1} />
        <Col xs={1} className="check-box">
          <div data-testid={`dataProtectionTermsAccept_${dataProtectionTermsAccept ? 'true' : 'false'}`}>
            <Checkbox
              checked={dataProtectionTermsAccept}
              onChange={handleChange}
              name="dataProtectionTermsAccept"
              disabled={isExpired || isWebIdConfirmed}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </div>
        </Col>
        <Col xs={10} className="text-container">
          <span className="text">
            {t('dataProtectionTermsAccept_start')}
            <a href={navToDatenschutz()} target="_blank" rel="noreferrer" className="text__link">
              {t('privacy_notice')}
            </a>{' '}
            {t('dataProtectionTermsAccept_end')}
          </span>
        </Col>
      </Row>
      <Row className="panel">
        <Col xs={1} />
        <Col xs={1} className="check-box">
          <div data-testid={`AldLeasingDataProtectionAccept_${AldLeasingDataProtectionAccept}`}>
            <Checkbox
              checked={AldLeasingDataProtectionAccept}
              onChange={handleChange}
              name="AldLeasingDataProtectionAccept"
              inputProps={{ 'aria-label': 'controlled' }}
              disabled={isExpired || isWebIdConfirmed}
            />
          </div>
        </Col>
        <Col xs={10} className="text-container">
          <span className="text">{t('AldLeasingDataProtectionAccept')}</span>
        </Col>
      </Row>
      <Row className="panel">
        <Col xs={2} className="check-box" />
        <Col xs={10} className="text-container">
          <Row>
            <Col className="col-12">
              <span className="text-flex">
                {t('agb')}
                <span onClick={() => handleTermsConditions()} className="text__link">
                  {t('agb_link')}
                </span>
              </span>
            </Col>
            <Col className="col-12">
              <span className="text-flex">
                {t('consent')}{' '}
                <span
                  className="text__link pl-1 pr-1"
                  onClick={() => {
                    window.location.href = 'mailto:datenschutz-widerspruch@aldautomotive.com';
                  }}
                >
                  {t('consent_link')}{' '}
                </span>{' '}
                widerrufen.
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="panel">
        <Col xs={12} md={6} />
        <Col xs={12} md={6} className="web-id-button-container_">
          <button className="web-id-button" disabled={!isAllChecked || isLoading} onClick={() => handleVideoID()}>
            {isLoading ? (
              <Spinner style={{ backgroundColor: 'green' }} animation="grow" />
            ) : (
              <span
                data-testid={`agreement_checkbox_${!isAllChecked && !isWebIdConfirmed}`}
                className={isAllChecked ? 'web-id-button__text-checked' : 'web-id-button__text'}
              >
                {isWebIdConfirmed ? t('confirm_button_webid_confirmed') : t('confirm_button')}
              </span>
            )}
          </button>
        </Col>
      </Row>
      <Row className="subpanel">
        <Col xs={12} md={6} />
      </Row>
      <Row className="panel" />
    </div>
  );
};

export default AgreementCheckbox;
