import axios, { AxiosRequestConfig, ResponseType } from 'axios';
import EnvironmentService from './EnvironmentService';

const createHeadersForRequest = () => ({
  [EnvironmentService.getApiSubscriptionKey()]: EnvironmentService.getApiSubscriptionValue(),
  'Cache-Control': 'no-cache',
});

function GET(url: string, responseType?: ResponseType) {
  const config: AxiosRequestConfig = {
    headers: { ...createHeadersForRequest() },
    responseType,
  };
  return axios.get(url, config);
}

function POST<T>(url: string, body: T, config?: AxiosRequestConfig) {
  const headers = { ...createHeadersForRequest(), ...(config?.headers || {}) };
  return axios.post<T>(url, body, { headers, ...config });
}

const HttpService = {
  GET,
  POST,
};

export default HttpService;
