import React from 'react';
import { Container } from 'react-bootstrap';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import SuccessPage from './components/SuccessPage';
import WebIdDocuments from './components/WebIdDocuments';
import { useFileContext } from './components/FileContext';
import Header from './components/Header';
import StoryBook from './components/StoryBook';
import './App.scss';
import EnvironmentService from './Services/EnvironmentService';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function App() {
  const {
    paramsData: { partner },
  } = useFileContext();
  return (
    <div className="App">
      <Router>
        <Header partner={partner} />
        <Container>
          <Routes>
            <Route path="/" element={<WebIdDocuments />} />
            <Route path="/success" element={<SuccessPage />} />
            {EnvironmentService.isOnDevOrTestStage() && <Route path="/storybook" element={<StoryBook />} />}
          </Routes>
        </Container>
      </Router>
    </div>
  );
}

export default App;
