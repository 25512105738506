/* eslint-disable no-undef */
import JSZip from 'jszip';
import { AxiosError } from 'axios';
import { FileData } from '../components/Interfaces';
import LogService from './LogService';
import HttpService from './httpService';
import NotificationService from './NotificationService';
import EnvironmentService from './EnvironmentService';

const getDocument = async ({ transactionId }: { transactionId: string | null }) => {
  const url = `${EnvironmentService.getApimBaseUrl()}/api/v1/blob/transactionId/${transactionId}`;
  try {
    const responseType = 'blob';
    const response = await HttpService.GET(url, responseType);
    const newZip = new JSZip();
    const zipped = await newZip.loadAsync(response.data);
    const docFile = Object.keys(zipped.files).map(async (filename) => {
      const content = await newZip.file(filename)?.async('blob');
      // eslint-disable-next-line no-shadow
      const url = window.URL.createObjectURL(new Blob([content as BlobPart], { type: 'application/pdf' }));
      // eslint-disable-next-line no-undef
      const file = new File([content as BlobPart], filename, { type: 'application/pdf' });
      return { url, filename, file };
    });
    return Promise.all(docFile) as unknown as Promise<FileData[]>;
  } catch (error) {
    const errorMessage = 'Ein Fehler im Backend ist aufgetreten, bitte versuchen Sie es später noch einmal.';
    const techMessage = `Error :${error.response.data.message} for transactionId=${transactionId}`;
    const errorContext = { transactionId, error: error?.response?.status, url };
    if (error instanceof AxiosError) {
      if (error.response && error.response.status === 400) {
        await NotificationService.showToastNotificationToUser(errorMessage, {
          persistant: true,
          techMessage: JSON.stringify(errorContext) + techMessage,
        });
      } else {
        await NotificationService.showToastNotificationToUser(errorMessage, {
          persistant: true,
          techMessage: JSON.stringify(errorContext) + techMessage,
        });
      }
    }
    LogService.error(error);
  }
  return [];
};

const getPdfUrl = async ({ transactionId }: { transactionId: string | null }) => {
  const documents = await getDocument({ transactionId });
  if (documents && documents.length > 0) {
    const { url, filename } = documents[0];
    const pdfData = { url, filename };
    return pdfData;
  }
  return { url: '', filename: '' };
};

const getTermsCondition = async ({ partner, angebotTyp }: { partner: string | null; angebotTyp: string | null }) => {
  const angeboteTyp = angebotTyp === 'privat' ? 'b2c' : 'b2b';
  const url = `${EnvironmentService.getApimBaseUrl()}/api/v1/termsconditions/${partner}/${angeboteTyp}`;
  try {
    const responseType = 'blob';
    const response = await HttpService.GET(url, responseType);
    const pdf = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
    const link = document.createElement('a');
    link.href = pdf;
    link.setAttribute('download', 'DataProtection.pdf');
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    const errorContext = { angebotTyp, partner, error: error?.response?.status, url };
    const errorMessage = 'Ein Fehler im Backend ist aufgetreten, bitte versuchen Sie es später noch einmal.';
    const techMessage = `Error :${error.response.data.message} for ${partner} with angebotTyp=${angebotTyp}`;
    if (error instanceof AxiosError) {
      if (error.response && error.response.status === 400) {
        await NotificationService.showToastNotificationToUser(errorMessage, {
          persistant: true,
          techMessage: JSON.stringify(errorContext) + techMessage,
        });
      } else {
        await NotificationService.showToastNotificationToUser(errorMessage, {
          persistant: true,
          techMessage: JSON.stringify(errorContext) + techMessage,
        });
      }
    }
    LogService.error(error);
  }
};

const startVideoId = async ({ partner, transactionId }: { partner: string | null; transactionId: string | null }) => {
  const url = `${EnvironmentService.getApimBaseUrl()}/api/v1/webid/${transactionId}/execute`;
  try {
    await HttpService.POST(url, {});
  } catch (error) {
    const errorMessage = 'Ein Fehler im Backend ist aufgetreten, bitte versuchen Sie es später noch einmal.';
    const errorContext = { transactionId, partner, error: error?.response?.status, url };
    const techMessage = `Error :${error.response.data.message} for transactionId=${transactionId} and partner=${partner}`;

    if (error instanceof AxiosError) {
      await NotificationService.showToastNotificationToUser(errorMessage, {
        persistant: true,
        techMessage: JSON.stringify(errorContext) + techMessage,
      });
    }
    LogService.error(error);
  }
};

const customerWebIdConfirmed = async ({
  transactionId,
  setIsWebIdConfirmed,
}: {
  transactionId: string | null;
  setIsWebIdConfirmed: (isWebIdConfirmed: boolean) => void;
}) => {
  const url = `${EnvironmentService.getApimBaseUrl()}/api/v1/webid/${transactionId}/status`;
  try {
    const response = await HttpService.GET(url);
    LogService.log('customerWebIdConfirmed', response);
    if (response.status === 200 && response.data === 'OK') {
      LogService.log('customerWebIdConfirmed', response);
      return setIsWebIdConfirmed(false);
    }
  } catch (error) {
    if (error instanceof AxiosError) {
      LogService.error('error=====>', { error });
      if (error.response && error.response.status === 405 && error.response.data.message === 'EXECUTED') {
        setIsWebIdConfirmed(true);
      }
    }
    LogService.error(error);
  }
};

const downloadZip = async ({
  transactionId,
  angebotId,
}: {
  transactionId: string | null;
  angebotId: string | null;
}) => {
  try {
    const url = `${EnvironmentService.getApimBaseUrl()}/api/v1/blob/transactionId/${transactionId}`;
    const responseType = 'blob';
    const response = await HttpService.GET(url, responseType);
    const newZip = new JSZip();
    const zipped = await newZip.loadAsync(response.data);
    const docFile = Object.keys(zipped.files).map(async (filename) => {
      const content = await newZip.file(filename)?.async('blob');
      const file = new File([content as BlobPart], filename, { type: 'application/pdf' });
      return { filename, file };
    });
    const files = await Promise.all(docFile);
    const zip = new JSZip();
    files.forEach((file) => {
      zip.file(file.filename, file.file);
    });
    const blob = await zip.generateAsync({ type: 'blob' });
    const uri = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = uri;
    link.setAttribute('download', `Leasing${angebotId}.zip`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    const errorMessage = 'Ein Fehler im Backend ist aufgetreten, bitte versuchen Sie es später noch einmal.';
    const techMessage = `Error :${error.response.data.message} for angeboteId=${angebotId} with transactionId=${transactionId}`;
    const errorContext = { transactionId, angebotId, error: error?.response?.status };
    if (error instanceof AxiosError) {
      await NotificationService.showToastNotificationToUser(errorMessage, {
        persistant: true,
        techMessage: JSON.stringify(errorContext) + techMessage,
      });
    }
    LogService.error(error);
  }
};

const checkDownloadReady = async ({
  transactionId,
  setIsPdfReady,
}: {
  transactionId: string | null;
  setIsPdfReady: (isPdfReady: boolean) => void;
}) => {
  const url = `${EnvironmentService.getApimBaseUrl()}/api/v1/blob/transactionId/${transactionId}`;
  try {
    const responseType = 'blob';
    const response = await HttpService.GET(url, responseType);
    const newZip = new JSZip();
    setIsPdfReady(true);
    const zipped = await newZip.loadAsync(response?.data);
    const docFile = Object.keys(zipped.files).map(async (filename) => {
      const content = await newZip.file(filename)?.async('blob');
      // eslint-disable-next-line no-shadow
      const url = window.URL.createObjectURL(new Blob([content as BlobPart], { type: 'application/pdf' }));
      // eslint-disable-next-line no-undef
      const file = new File([content as BlobPart], filename, { type: 'application/pdf' });
      return { url, filename, file };
    });
    return Promise.all(docFile) as unknown as Promise<FileData[]>;
  } catch (error) {
    const errorMessage = 'Ein Fehler im Backend ist aufgetreten, bitte versuchen Sie es später noch einmal.';
    const techMessage = `Error :${error?.response?.data?.message} for transactionId=${transactionId}`;
    const errorContext = { transactionId, error: error?.response?.status };
    if (error instanceof AxiosError) {
      await NotificationService.showToastNotificationToUser(errorMessage, {
        persistant: true,
        techMessage: JSON.stringify(errorContext) + techMessage,
      });
    }
    LogService.error(error);
  }
  return [];
};

const hasExpired = async ({
  transactionId,
  setIsExpired,
}: {
  transactionId: string | null;
  setIsExpired: (isExpired: boolean) => void;
}) => {
  const url = `${EnvironmentService.getApimBaseUrl()}/api/v1/webid/${transactionId}/status`;
  try {
    const response = await HttpService.GET(url);
    if (response.status === 200) {
      setIsExpired(false);
    }
  } catch (error) {
    LogService.error('error', error);
    if (error instanceof AxiosError) {
      LogService.error('error=====>hasExpired', { error });
      if (error?.response && error?.response?.status === 410 && error?.response?.data?.message === 'EXPIRED') {
        setIsExpired(true);
      }
    }
  }
};

// eslint-disable-next-line import/prefer-default-export
export const documentService = {
  getDocument,
  getPdfUrl,
  getTermsCondition,
  startVideoId,
  downloadZip,
  checkDownloadReady,
  hasExpired,
  customerWebIdConfirmed,
};
