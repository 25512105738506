import { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { useLocation } from 'react-router-dom';
import { useFileContext } from '../components/FileContext';
import { ParamData } from '../components/Interfaces';

const useSearchParams = () => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const { setParamsData }: { setParamsData: Function } = useFileContext();
  const { search }: { search: string } = useLocation();
  const searchParams: URLSearchParams = new URLSearchParams(search);

  const params: ParamData = {
    decodedToken: '',
    token: searchParams.get('token'),
    partner: searchParams.get('partner'),
    angebotId: searchParams.get('angebotId'),
    angebotTyp: searchParams.get('angebotTyp'),
    kundenName: searchParams.get('kundenName'),
    transactionId: searchParams.get('transactionId'),
  };
  const decoded = params.token ? jwtDecode(params.token as string) : '';

  useEffect(() => {
    setParamsData({
      ...params,
      decodedToken: decoded,
      token: params?.token,
      angebotId: params?.angebotId,
      partner: params?.partner,
      angeboteTyp: params?.angebotTyp,
      kundenName: params?.kundenName,
      transactionId: params?.transactionId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const TokenService = {
  useSearchParams,
};

export default TokenService;
