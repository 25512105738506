import React from 'react';
import { Store } from 'react-notifications-component';
import '../components/WebIdDocuments.scss';

import ReportingService from './ReportingService';

type ToastNotificationParams = {
  persistant?: boolean;
  techMessage?: string;
  isExpired?: boolean;
};

const showToastNotificationToUser = (
  message: string,
  params: ToastNotificationParams = { persistant: false, techMessage: '', isExpired: false },
): Promise<boolean> => {
  const fiveMinutesTimeout = 1000 * 60 * 5;

  const expired = params.isExpired;
  const dismiss = {
    click: params.persistant,
    touch: params.persistant,
    duration: params.persistant ? fiveMinutesTimeout : 2500,
  };

  if (params.persistant) {
    ReportingService.logToBackEnd({ userMessage: message, techMessage: params.techMessage });
  }

  const promise = new Promise<boolean>((resolve) => {
    Store.addNotification({
      content: () => (
        <div className={`notification-custom-Danger ${expired ? 'expired' : ''}`}>
          <div className="mb-2 text-center">
            <span dangerouslySetInnerHTML={{ __html: message }} />
          </div>
        </div>
      ),
      container: expired ? 'top-center' : 'bottom-left',
      dismiss: expired ? undefined : dismiss,
      onRemoval: (_id: string, removedBy: string) => {
        if (removedBy === 'manual') {
          resolve(false);
        } else {
          resolve(true);
        }
      },
    });
  });
  return promise;
};

const NotificationService = {
  showToastNotificationToUser,
};

export default NotificationService;
