/* eslint-disable no-console  */

const isProduction = process.env.NODE_ENV === 'production';

const log = (message: string, ...parameters: unknown[]) => {
  if (isProduction) return;
  console.log(message, ...parameters);
};

const debug = (message: string, ...parameters: unknown[]) => {
  if (isProduction) return;
  console.debug(message, ...parameters);
};

const error = (message: string, ...parameters: unknown[]) => {
  console.error(message, ...parameters);
};

const errorFunc = (error_: Error) => {
  if (isProduction) return;
  console.error(error_);
};

const warn = (message: string, ...parameters: unknown[]) => {
  console.warn(message, ...parameters);
};

const LogService = {
  log,
  debug,
  error,
  warn,
  errorFunc,
};

export default LogService;
