import React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function PressButtonIcon() {
  return (
    <svg width="98" height="129" viewBox="0 0 98 128" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_2136_12165)">
        <path
          d="M5 5C5 2.79086 6.79086 1 9 1H77.324C78.3905 1 79.4129 1.42593 80.1639 2.18315L85.5507 7.61408L91.2116 13.3333C91.9529 14.0823 92.3687 15.0935 92.3687 16.1472V108.337C92.3687 110.546 90.5778 112.337 88.3687 112.337H9C6.79086 112.337 5 110.546 5 108.337V5Z"
          fill="white"
        />
      </g>
      <path
        d="M5 5C5 2.79086 6.79086 1 9 1H77.324C78.3905 1 79.4129 1.42593 80.1639 2.18315L85.5507 7.61408L91.2116 13.3333C91.9529 14.0823 92.3687 15.0935 92.3687 16.1472V108.337C92.3687 110.546 90.5778 112.337 88.3687 112.337H9C6.79086 112.337 5 110.546 5 108.337V5Z"
        fill="white"
      />
      <line
        x1="36.2803"
        y1="30.7061"
        x2="76.2803"
        y2="30.7061"
        stroke="#031A6B"
        strokeOpacity="0.3"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="36.2803"
        y1="63"
        x2="66.7376"
        y2="63"
        stroke="#031A6B"
        strokeOpacity="0.3"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="36.2803"
        y1="24.7061"
        x2="76.2803"
        y2="24.7061"
        stroke="#031A6B"
        strokeOpacity="0.3"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="36.29"
        y1="56.4497"
        x2="74.29"
        y2="56.6964"
        stroke="#031A6B"
        strokeOpacity="0.3"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="36.2803"
        y1="36.7061"
        x2="62.2803"
        y2="36.7061"
        stroke="#031A6B"
        strokeOpacity="0.3"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <line
        x1="36.2803"
        y1="69.7061"
        x2="70.2803"
        y2="69.7061"
        stroke="#031A6B"
        strokeOpacity="0.3"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <g filter="url(#filter1_d_2136_12165)">
        <path d="M79.1314 14.1203L92.252 14.7764L78.4754 0.999808L79.1314 14.1203Z" fill="white" />
      </g>
      <rect x="20.25" y="20.25" width="7.5" height="7.5" rx="3" fill="#4EA699" />
      <mask
        id="mask0_2136_12165"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="19"
        y="19"
        width="10"
        height="10"
      >
        <path d="M19 19H29V29H19V19Z" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2136_12165)">
        <path
          d="M23.3857 25.6873L26.3647 22.6978L25.8752 22.208L23.3857 24.6978L22.1565 23.479L21.667 23.9685L23.3857 25.6873Z"
          fill="white"
        />
        <path
          d="M23.3857 25.6873L26.3647 22.6978L25.8752 22.208L23.3857 24.6978L22.1565 23.479L21.667 23.9685L23.3857 25.6873Z"
          fill="white"
        />
      </g>
      <rect x="20.25" y="51.25" width="7.5" height="7.5" rx="3" fill="#4EA699" />
      <mask
        id="mask1_2136_12165"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x="19"
        y="50"
        width="10"
        height="10"
      >
        <path d="M19 50H29V60H19V50Z" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask1_2136_12165)">
        <path
          d="M23.3857 56.6873L26.3647 53.6978L25.8752 53.208L23.3857 55.6978L22.1565 54.479L21.667 54.9685L23.3857 56.6873Z"
          fill="white"
        />
        <path
          d="M23.3857 56.6873L26.3647 53.6978L25.8752 53.208L23.3857 55.6978L22.1565 54.479L21.667 54.9685L23.3857 56.6873Z"
          fill="white"
        />
      </g>
      <rect x="35" y="88" width="41" height="7" rx="2" fill="#818CB5" />
      <g filter="url(#filter2_d_2136_12165)">
        <path
          d="M58.0971 88.0598L77.9072 72.8987C78.8845 72.1663 78.4345 70.6336 77.1844 70.5282L70.5698 70.0045L78.9892 49.7469C79.2643 49.085 78.9733 48.291 78.2671 47.9975L70.1023 44.6041C69.4404 44.329 68.6464 44.62 68.3529 45.3262L59.9335 65.5838L54.8963 61.2649C53.9838 60.4715 52.5803 61.2337 52.7062 62.4248L55.9342 87.1609C56.1152 88.2196 57.2629 88.6966 58.0969 88.0597L58.0971 88.0598Z"
          fill="#4EA699"
        />
      </g>
      <rect x="38" y="90.5" width="35" height="2" rx="1" fill="#FDFDFD" />
      <defs>
        <filter
          id="filter0_d_2136_12165"
          x="0"
          y="1"
          width="97.3691"
          height="126.337"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.614167 0 0 0 0 0.614167 0 0 0 0 0.733333 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2136_12165" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2136_12165" result="shape" />
        </filter>
        <filter
          id="filter1_d_2136_12165"
          x="75.4756"
          y="0"
          width="19.7764"
          height="19.7764"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.832587 0 0 0 0 0.851891 0 0 0 0 0.920833 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2136_12165" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2136_12165" result="shape" />
        </filter>
        <filter
          id="filter2_d_2136_12165"
          x="48.6982"
          y="44.5015"
          width="34.3936"
          height="51.8452"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2136_12165" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2136_12165" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default PressButtonIcon;
