/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { documentService } from '../Services/DocumentService';
import I18nS from '../Services/I18nService';
import { PDFIcon } from '../assets/icons';
import CheckboxIcon from '../assets/icons/checkbox-icon';
import DataSearchIcon from '../assets/icons/data-search-icon';
import DownloadIcon from '../assets/icons/download-icon';
import NumberOneIcon from '../assets/icons/one-icon';
import PressButtonIcon from '../assets/icons/press-button-icon';
import NumberThreeIcon from '../assets/icons/three-icon';
import NumberTwoIcon from '../assets/icons/two-icon';
import './WebIdDocuments.scss';
import { useFileContext } from './FileContext';
import PdfModal from './PdfModal';
import { StepsProps } from './Interfaces';

const DocumentButton = () => {
  const [isPdfReady, setIsPdfReady] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);

  const { t } = I18nS;
  const {
    paramsData: { angebotId, kundenName, transactionId },
  } = useFileContext();
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (transactionId) {
      documentService.checkDownloadReady({ transactionId, setIsPdfReady });
    }
  }, [transactionId]);

  const DownloadZipFile = async () => {
    await documentService.downloadZip({ transactionId, angebotId });
  };

  useEffect(() => {
    if (isPdfReady) {
      setDisabled(false);
    }
  }, [isPdfReady]);

  return (
    <div data-testid="document-button" className="area">
      <Row className="content-title panel">
        <Col>{t('steps_to_digitally_sign_title')}</Col>
      </Row>
      <Row className="panel">
        <Row className="signing-steps" data-testid="procedures">
          <Col sm={4}>
            {Step({
              ...{
                icon1: <NumberOneIcon />,
                icon2: <DataSearchIcon />,
                text: `${t('step_one')}`,
              },
            })}
          </Col>
          <Col sm={4}>
            {Step({
              ...{
                icon1: <NumberTwoIcon />,
                icon2: <CheckboxIcon />,
                text: `${t('step_two')}`,
              },
            })}
          </Col>
          <Col sm={4}>
            {Step({
              ...{
                icon1: <NumberThreeIcon />,
                icon2: <PressButtonIcon />,
                text: `${t('step_three')}`,
              },
            })}
          </Col>
        </Row>
      </Row>
      <Row className="panel">
        <Col xs={12} md={4} className="title subpanel">
          {t('contract_documents')}
        </Col>
        <Col xs={12} md={8}>
          <Container>
            <Row>
              <Col xs={12} data-testid="document_button_true">
                <Container className="pdf-link-container">
                  <Row>
                    <Col xs={12} md={2}>
                      <PDFIcon />
                    </Col>
                    <Col xs={12} md={4} className="document-button-text">
                      <div>
                        {t('leasing')}: {angebotId}
                      </div>
                      <div>
                        {t('customer')}: {kundenName}
                      </div>
                    </Col>
                    <Col
                      xs={12}
                      md={6}
                      className={disabled ? 'document-button__subtitle-disabled' : 'document-button__subtitle'}
                      onClick={() => (disabled ? '' : DownloadZipFile())}
                      disabled={disabled}
                    >
                      <DownloadIcon />
                      <span className={disabled ? 'open-button-disabled' : 'open-button'}>Datei herunterladen</span>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col xs={12} className="subtitle subpanel">
                <p className="text">{t('view_leasing_contract')} </p>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      {show && <PdfModal show={show} setShow={setShow} />}
    </div>
  );
};

export default DocumentButton;

const Step = ({ icon1, icon2, text }: StepsProps) => (
  <Row className="signing-steps-body">
    <Col>{icon1}</Col>
    <Col>{icon2}</Col>
    <Col className="text-left">
      <span className="sigining-steps-search-text">{text}</span>
    </Col>
  </Row>
);
