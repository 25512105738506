import React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function NumberOneIcon() {
  return (
    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.53196 0.545454V18H4.84162V4.04829H4.73935L0.742188 6.55398V3.28125L5.06321 0.545454H8.53196ZM13.8991 18.2216C13.3366 18.2216 12.8537 18.0227 12.4503 17.625C12.0526 17.2216 11.8537 16.7386 11.8537 16.1761C11.8537 15.6193 12.0526 15.142 12.4503 14.7443C12.8537 14.3466 13.3366 14.1477 13.8991 14.1477C14.4446 14.1477 14.9219 14.3466 15.331 14.7443C15.7401 15.142 15.9446 15.6193 15.9446 16.1761C15.9446 16.5511 15.848 16.8949 15.6548 17.2074C15.4673 17.5142 15.2202 17.7614 14.9134 17.9489C14.6065 18.1307 14.2685 18.2216 13.8991 18.2216Z"
        fill="#4EA699"
      />
    </svg>
  );
}

export default NumberOneIcon;
