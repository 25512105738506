import React from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function NumberTwoIcon() {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.28196 18V15.3409L6.49503 9.58807C7.02344 9.0767 7.46662 8.61648 7.82457 8.20739C8.18821 7.7983 8.46378 7.39773 8.65128 7.00568C8.83878 6.60795 8.93253 6.17898 8.93253 5.71875C8.93253 5.20739 8.81605 4.76705 8.5831 4.39773C8.35014 4.02273 8.03196 3.7358 7.62855 3.53693C7.22514 3.33239 6.76776 3.23011 6.25639 3.23011C5.7223 3.23011 5.25639 3.33807 4.85866 3.55398C4.46094 3.76989 4.15412 4.07955 3.93821 4.48295C3.7223 4.88636 3.61435 5.36648 3.61435 5.92329H0.111506C0.111506 4.78125 0.370028 3.78977 0.887074 2.94886C1.40412 2.10795 2.12855 1.45739 3.06037 0.997158C3.99219 0.536931 5.06605 0.306818 6.28196 0.306818C7.53196 0.306818 8.62003 0.528409 9.54617 0.971591C10.478 1.40909 11.2024 2.01705 11.7195 2.79545C12.2365 3.57386 12.495 4.46591 12.495 5.47159C12.495 6.13068 12.3643 6.78125 12.103 7.4233C11.8473 8.06534 11.3899 8.77841 10.7308 9.5625C10.0717 10.3409 9.14276 11.2756 7.94389 12.3665L5.3956 14.8636V14.983H12.7251V18H0.28196ZM17.5554 18.2216C16.9929 18.2216 16.5099 18.0227 16.1065 17.625C15.7088 17.2216 15.5099 16.7386 15.5099 16.1761C15.5099 15.6193 15.7088 15.142 16.1065 14.7443C16.5099 14.3466 16.9929 14.1477 17.5554 14.1477C18.1009 14.1477 18.5781 14.3466 18.9872 14.7443C19.3963 15.142 19.6009 15.6193 19.6009 16.1761C19.6009 16.5511 19.5043 16.8949 19.3111 17.2074C19.1236 17.5142 18.8764 17.7614 18.5696 17.9489C18.2628 18.1307 17.9247 18.2216 17.5554 18.2216Z"
        fill="#4EA699"
      />
    </svg>
  );
}

export default NumberTwoIcon;
