import React from 'react';
import { ReactNotifications } from 'react-notifications-component';
import { Button } from 'react-bootstrap';
import Header, { logos } from './Header';
import NotificationService from '../Services/NotificationService';

function StoryBook() {
  return (
    <div>
      <h1>StoryBook</h1>
      <ReactNotifications />
      {Object.keys(logos).map((partner) => (
        <Header {...{ partner }} key={partner} />
      ))}
      <br />
      <br />
      <br />
      <br />
      <h2> Toast Messages </h2>
      <Button
        onClick={() =>
          NotificationService.showToastNotificationToUser('Persistant Msg, <br /> My Error as second line', {
            persistant: true,
            techMessage: JSON.stringify({ error: 'My Error', bla: 'bla', blub: 'blub' }),
          })
        }
      >
        Persistent Toast
      </Button>
      <Button
        onClick={() =>
          NotificationService.showToastNotificationToUser('Toast Msg', {
            persistant: true,
            techMessage: JSON.stringify({ error: '500 Error occured' }),
          })
        }
      >
        Normal Toast
      </Button>
      <br />
      <br />
      <br />
    </div>
  );
}

export default StoryBook;
