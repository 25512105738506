import React from 'react';

const DownloadIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ maskType: 'alpha' }}
  >
    <mask id="mask0_205_1622" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_205_1622)">
      <path
        className="my-svg-path"
        d="M6.0641 19.5002C5.6343 19.5002 5.26617 19.3469 4.9597 19.0405C4.65323 18.734 4.5 18.3659 4.5 17.9361V14.963H5.75637V17.9361C5.75637 18.013 5.78843 18.0835 5.85255 18.1476C5.91665 18.2117 5.98717 18.2438 6.0641 18.2438H17.9358C18.0128 18.2438 18.0833 18.2117 18.1474 18.1476C18.2115 18.0835 18.2436 18.013 18.2436 17.9361V14.963H19.5V17.9361C19.5 18.3659 19.3467 18.734 19.0403 19.0405C18.7338 19.3469 18.3656 19.5002 17.9358 19.5002H6.0641ZM12 15.7002L7.81543 11.5156L8.71025 10.6169L11.3718 13.272V4.32715H12.6282V13.272L15.2897 10.6169L16.1845 11.5156L12 15.7002Z"
        fill="#4EA699"
      />
    </g>
  </svg>
);

export default DownloadIcon;
