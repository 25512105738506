import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component';
import AgreementCheckbox from './AgreementCheckBox';
import DocumentButton from './DocumentButton';

const WebIdDocuments = () => {
  const navigate = useNavigate();

  return (
    <div>
      <ReactNotifications />
      <DocumentButton />
      <AgreementCheckbox navigate={navigate} />
    </div>
  );
};

export default WebIdDocuments;
