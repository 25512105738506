import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Skeleton from 'react-loading-skeleton';
import LogService from '../Services/LogService';
import { PdfViewerProps } from './Interfaces';
import 'react-loading-skeleton/dist/skeleton.css';
import './PdfViewer.scss';

pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';

const PdfViewer = ({ pdfUrl }: PdfViewerProps) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const onDocumentLoadSuccess = ({ numPages: pageCount }: { numPages: number }) => {
    setNumPages(pageCount);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, [onDocumentLoadSuccess]);

  const onLoadError = (error: Error) => {
    LogService.errorFunc(error);
  };

  return (
    <div className="page">
      <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess} onLoadError={onLoadError} loading="Loading...">
        {isLoading && <Skeleton count={numPages} />}
        {Array.from({ length: numPages }, (_, i) => (
          <Page pageNumber={i + 1} height={500} width={500} key={`page-${i + 1}`} loading="" scale={2} />
        ))}
      </Document>
    </div>
  );
};

export default PdfViewer;
