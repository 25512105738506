declare global {
  interface Window {
    DIGITAL_SIGNATURE_APIM_BASE_URL: string;
    APIM_SUBSCRIPTION_KEY: string;
    APIM_SUBSCRIPTION_VALUE: string;
    REPORTER_BASE_URL: string;
    DATENSCHUTZ_URL: string;
  }
}

const isTest = process.env.NODE_ENV === 'test';
const isDev = process.env.NODE_ENV === 'development';
const isProduction = process.env.NODE_ENV === 'production';

const {
  REACT_APP_DIGITAL_SIGNATURE_APIM_BASE_URL,
  REACT_APP_APIM_SUBSCRIPTION_KEY,
  REACT_APP_APIM_SUBSCRIPTION_VALUE,
  REACT_APP_REPORTER_BASE_URL,
  REACT_APP_DATENSCHUTZ_URL,
} = process.env;

const isOnDevOrTestStage = (): boolean => {
  const baseUrl = getApimBaseUrl();
  return !!baseUrl && (baseUrl.includes('//api.staging.aldautomotive.com') || baseUrl.includes('//localhost'));
};

const getDatenschutzUrl = (): string => {
  if (isProduction) {
    return window?.DATENSCHUTZ_URL ?? '';
  }
  return REACT_APP_DATENSCHUTZ_URL ?? '';
};

const getReporterUrl = () => {
  if (isProduction) {
    return window.REPORTER_BASE_URL ?? '';
  }
  return REACT_APP_REPORTER_BASE_URL ?? '';
};

const getApimBaseUrl = (): string => {
  if (isProduction) {
    return window?.DIGITAL_SIGNATURE_APIM_BASE_URL ?? '';
  }
  return REACT_APP_DIGITAL_SIGNATURE_APIM_BASE_URL ?? '';
};

const getApiSubscriptionKey = (): string => {
  if (isProduction) {
    return window?.APIM_SUBSCRIPTION_KEY ?? '';
  }
  return REACT_APP_APIM_SUBSCRIPTION_KEY ?? '';
};

const getApiSubscriptionValue = (): string => {
  if (isProduction) {
    return window?.APIM_SUBSCRIPTION_VALUE ?? '';
  }
  return REACT_APP_APIM_SUBSCRIPTION_VALUE ?? '';
};

const EnvironmentService = {
  isTest,
  isDev,
  isProduction,
  isOnDevOrTestStage,
  getReporterUrl,
  getApimBaseUrl,
  getApiSubscriptionKey,
  getApiSubscriptionValue,
  getDatenschutzUrl,
};

export default EnvironmentService;
