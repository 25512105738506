import React, { createContext, useContext, useState } from 'react';
import { FileData, IFileData, ParamData } from './Interfaces';

const FileContext = createContext<IFileData>({} as IFileData);

export default FileContext;

export const useFileContext = () => useContext(FileContext);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FileProvider = ({ children }: { children: React.ReactNode }) => {
  const [file, setFile] = useState<FileData[] | undefined>();
  const [paramsData, setParamsData] = useState<ParamData>({
    decodedToken: null,
    token: null,
    partner: null,
    angebotId: null,
    angebotTyp: null,
    kundenName: null,
    transactionId: null,
  });
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextData = {
    paramsData,
    setParamsData,
    file,
    setFile,
    isLoading: false,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setIsLoading: () => {},
  };

  return <FileContext.Provider value={contextData}>{children}</FileContext.Provider>;
};
