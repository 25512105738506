import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useFileContext } from './FileContext';
import DocumentList from './DocumentList';
import PdfViewer from './PdfViewer';
import { documentService } from '../Services/DocumentService';
import './PdfModal.scss';
import { FileData, showProps } from './Interfaces';
import I18nS from '../Services/I18nService';
import LogService from '../Services/LogService';

const getPercentage = (num: number, total: number) => (total === 0 ? 0 : Math.round((num / total) * 100));

const PdfModal = ({ show, setShow }: showProps) => {
  const { t } = I18nS;
  const [onStartPdf, setOnStartPdf] = useState<boolean>(false);
  const {
    file,
    setFile,
    paramsData: { transactionId },
  } = useFileContext();
  const [now, setNow] = useState(getPercentage(1, file?.length as number));
  const [showPdf, setShowPdf] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<string>('');
  const [filename, setFilename] = useState<string | undefined>('');
  const [selected, setSelected] = useState({
    filename,
  });

  const handleClose = () => setShow(false);

  const handleActiveState = (index: number, docName: string, url: string) => {
    setNow(getPercentage(index, file?.length as number) - 25);
    setOnStartPdf(!onStartPdf);
    setShowPdf(!showPdf);
    setSelected({ filename: docName });
    setPdfUrl(url);
  };

  useEffect(() => {
    const downloadPdf = async () => {
      const documents = await documentService.getDocument({ transactionId });
      LogService.log('documents', documents);
      setFile(documents);
    };
    const getDefaultPdfUrl = async () => {
      const pdfData = await documentService.getPdfUrl({ transactionId });
      LogService.log('pdfData', pdfData);
      setFilename(pdfData?.filename);
      if (selected.filename === '') {
        setPdfUrl(pdfData?.url);
      }
      setOnStartPdf(true);
    };
    getDefaultPdfUrl();
    downloadPdf();
  }, [documentService.getDocument, documentService.getPdfUrl, onStartPdf]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <div data-testid="pdf_modal">
        <Modal size="lg" show={show} onHide={handleClose}>
          <Modal.Header className="file-title">
            <div className="d-flex flex-column">
              <div className="pdf-title">
                {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  file?.map((pdf: FileData, index: number) => (
                    <span
                      key={pdf.url}
                      onClick={() => {
                        handleActiveState(index + 1, pdf.filename, pdf.url);
                      }}
                    >
                      <DocumentList docName={pdf.filename} selected={selected} index={index} />
                    </span>
                  ))
                }
                <button className="close-button" onClick={handleClose} data-testid="close_button">
                  <span className="close-button-text">{t('close_button')}</span>
                </button>
              </div>
              <ProgressBar now={now} visuallyHidden />
            </div>
          </Modal.Header>
          <Modal.Body>
            {onStartPdf ? (
              <PdfViewer pdfUrl={pdfUrl} scale={3} />
            ) : (
              showPdf && !onStartPdf && <PdfViewer pdfUrl={pdfUrl} scale={2} />
            )}
          </Modal.Body>
          {onStartPdf && (
            <Modal.Footer className="modal-pdf-footer">
              <button className="footer-close-button" onClick={handleClose}>
                <span className="footer-close-button-text">{t('close_button')}</span>
              </button>
            </Modal.Footer>
          )}
        </Modal>
      </div>
    </>
  );
};

export default PdfModal;
