import React from 'react';
import './DocumentList.scss';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DocumentList = ({ docName, selected }: any) => (
  <div className="document-body">
    <span className={selected.filename === docName ? 'document-button-selected' : 'document-button'}>{docName}</span>
  </div>
);

export default DocumentList;
